import React from 'react';

class ClientToPay extends React.Component {
  render() {
    return(
      <div className="form-group row">
        <label htmlFor="clientToPay" className="col-sm-5 col-form-label text-sm-right">Client to Pay</label>
        <div className="col width-12-rem">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">£</div>
            </div>
            <input type="text" className="form-control" id="clientToPay" aria-describedby="clientToPay"
              readOnly value={this.props.clientToPay} placeholder="0.00" />
          </div>
        </div>
      </div>
    )
  }
}

export default ClientToPay;
