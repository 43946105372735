import React from 'react';

class DiscountCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.billTotalRef = React.createRef();
  }

  handleDiscountIncludedChange = (event) => {
    let discountIncluded = event.target.checked;
    this.props.onDiscountIncludedChange(discountIncluded);
  }

  render() {
    return (

      <div className="form-group row">
        <div className="col-sm-5"></div>
        <div className="col">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="discountIncluded"
            checked={this.props.discountIncluded} onChange={this.handleDiscountIncludedChange} />
            <label className="form-check-label" htmlFor="discountIncluded">
              Other discounts?
            </label>
          </div>
        </div>
      </div>
    )
  }
}

export default DiscountCheckbox;
