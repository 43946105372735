import React from 'react';

class ReclainOnVoucher extends React.Component {
  render() {
    return(
      <div className="form-group row">
        <label htmlFor="reclaimOnVoucher" className="col-sm-5 col-form-label text-sm-right">Reclaim on Voucher</label>
        <div className="col width-12-rem">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">£</div>
            </div>
            <input type="text" className="form-control" id="reclaimOnVoucher" aria-describedby="reclaimOnVoucher"
              readOnly value={this.props.reclaimOnVoucher} placeholder="0.00" />
          </div>
        </div>
      </div>
    )
  }
}

export default ReclainOnVoucher;
