import React from 'react';
import { currencyRe } from './currencyRe.js';

class BillTotal extends React.Component {
  handleBillTotalChange = (event) => {
    if (event.target.validity.valid) {
      this.props.onBillTotalChange(event.target.value);
    }
  }

  formatTotalBillIntoCurrency = (event) => {
    let billTotal = event.target.value;

    if (billTotal !== "") {
      let billTotalParsed = Number.parseFloat(billTotal);
      let billTotalFormatted = billTotalParsed.toFixed(2);
      this.props.onBillTotalChange(billTotalFormatted);
    }
  }

  handleEnterKeyForTotalBill = (event) => {
    if (event.keyCode === 13) {
      this.props.serviceTotalRef.current.focus();
    }
  }

  render() {
    return (
      <div className="form-group row">
        <label htmlFor="billTotal" className="col-sm-5 col-form-label text-sm-right">Bill Total</label>
        <div className="col width-12-rem">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">£</div>
            </div>
            <input type="text" className="form-control" id="billTotal" aria-describedby="billTotal"
              value={this.props.billTotal} onChange={this.handleBillTotalChange}
              onBlur={this.formatTotalBillIntoCurrency} onKeyDown={this.handleEnterKeyForTotalBill}
              placeholder="0.00" inputMode="decimal" pattern={currencyRe} />
          </div>
        </div>
      </div>
    )
  }
}

export default BillTotal;
