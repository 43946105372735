import React from 'react';
import { currencyRe } from './currencyRe.js';

class ServiceTotal extends React.Component {
  handleServiceTotalChange = (event) => {
    if (event.target.validity.valid) {
      this.props.onServiceTotalChange(event.target.value);
    }
  }

  formatServiceTotalIntoCurrency = (event) => {
    let serviceTotal = event.target.value;

    if (serviceTotal !== "") {
      let serviceTotalParsed = Number.parseFloat(serviceTotal);
      let serviceTotalFormatted = serviceTotalParsed.toFixed(2);
      this.props.onServiceTotalChange(serviceTotalFormatted);
    }
  }

  handleEnterKeyForServiceTotal = (event) => {
    if (event.keyCode === 13) {
      this.props.alcoholTotalRef.current.focus();
    }
  }

  render() {
    return (
      <div className="form-group row" >
        <label htmlFor="serviceTotal" className="col-sm-5 col-form-label text-sm-right">Service</label>
        <div className="col width-12-rem">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">£</div>
            </div>
            <input type="text" className="form-control" id="serviceTotal" aria-describedby="serviceTotal"
              value={this.props.serviceTotal} onChange={this.handleServiceTotalChange}
              onBlur={this.formatServiceTotalIntoCurrency} onKeyDown={this.handleEnterKeyForServiceTotal}
              ref={this.props.innerRef} placeholder="0.00" inputMode="decimal" pattern={currencyRe} />
          </div>
        </div>
      </div>
    )
  }
}

export default React.forwardRef((props, ref) => <ServiceTotal innerRef={ref} {...props} />);
