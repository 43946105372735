import React from 'react';
import BillTotal from './CalculatorFields/BillTotal';
import ServiceCheckbox from './CalculatorFields/ServiceCheckbox';
import ServiceTotal from './CalculatorFields/ServiceTotal';
import AlcoholCheckbox from './CalculatorFields/AlcoholCheckbox';
import AlcoholTotal from './CalculatorFields/AlcoholTotal';
import PeopleCount from './CalculatorFields/PeopleCount';
import ClientToPay from './CalculatorFields/ClientToPay';
import ReclaimOnVoucher from './CalculatorFields/ReclaimOnVoucher';
import ResetButton from './CalculatorFields/ResetButton';
import DiscountCheckbox from './CalculatorFields/DiscountCheckbox';
import DiscountAmount from './CalculatorFields/DiscountAmount';
import DiscountPercentage from './CalculatorFields/DiscountPercentage';
import DiscountIncludesAlcoholCheckbox from './CalculatorFields/DiscountIncludesAlcoholCheckbox';
import { parseCurrencySafe } from './CalculatorFields/parseCurrencySafe';
import { parsePeopleNumerSafe } from './CalculatorFields/parsePeopleNumerSafe';

class Calculator extends React.Component {
  constructor(props) {
    super(props);

    this.state = { billTotal: "", serviceIncluded: false, serviceTotal: "",
      alcoholIncluded: true, alcoholTotal: "", peopleCount: "", clientToPay: "",
      reclaimOnVoucher: "", discountIncluded: false,
      discountAmount: "", discountPercentage: "", discountFieldSelection: "", alcoholDiscountIncluded: true };

    this.billTotalRef = React.createRef();
    this.serviceTotalRef = React.createRef();
    this.alcoholTotalRef = React.createRef();
    this.peopleCountRef = React.createRef();
    this.discountTotalRef = React.createRef();
  }

  componentDidMount() {
    this.performCalculations();
  }

  handleSubmit = (event) => event.preventDefault();

  handleBillTotalChange = (billTotal) => this.setState({billTotal: billTotal}, () => this.performCalculations());
  handleServiceIncludedChange = (serviceIncluded) => this.setState({serviceIncluded: serviceIncluded}, () => this.performCalculations());
  handleServiceTotalChange = (serviceTotal) => this.setState({serviceTotal: serviceTotal}, () => this.performCalculations());
  handleAlcoholIncludedChange = (alcoholIncluded) => this.setState({alcoholIncluded: alcoholIncluded}, () => this.performCalculations());
  handleAlcoholTotalChange = (alcoholTotal) => this.setState({alcoholTotal: alcoholTotal}, () => this.performCalculations());
  handlePeopleCountChange = (peopleCount) => this.setState({peopleCount: peopleCount}, () => this.performCalculations());
  handleDiscountIncludedChange = (discountIncluded) => this.setState({discountIncluded: discountIncluded}, () => this.performCalculations())

  resetCalculator = () => this.setState({ billTotal: "", serviceIncluded: false, serviceTotal: "", alcoholTotal: "", peopleCount: "", clientToPay: "", reclaimOnVoucher: "", discountAmount: "", discountPercentage: "" })

  handleDiscountAmountChange = (discountAmount, newSelection) => {
    let selection = this.state.discountFieldSelection;
    let discountPercentage = this.state.discountPercentage;

    if (newSelection) {
      selection = "amount";
      discountPercentage = "";
    }

    this.setState({discountAmount: discountAmount, discountFieldSelection: selection, discountPercentage: discountPercentage}, () => this.performCalculations());
  }

  handleDiscountPercentageChange = (discountPercentage, newSelection) => {
    let selection = this.state.discountFieldSelection;
    let discountAmount = this.state.discountAmount;

    if (newSelection) {
      selection = "percentage";
      discountAmount = ""
    }

    this.setState({discountPercentage: discountPercentage, discountFieldSelection: selection, discountAmount: discountAmount}, () => this.performCalculations());
  }

  handleAlcoholDiscountIncludeChange = (alcoholDiscountIncluded) => this.setState({alcoholDiscountIncluded: alcoholDiscountIncluded}, () => this.performCalculations());

  getFoodBill() {
    let formattedBill = parseCurrencySafe(this.state.billTotal);
    let formattedService = this.state.serviceIncluded ? parseCurrencySafe(this.state.serviceTotal) : 0;
    let formattedAlcohol = this.state.alcoholIncluded ? parseCurrencySafe(this.state.alcoholTotal) : 0;

    formattedBill = formattedBill - formattedService - formattedAlcohol;

    if (formattedBill < 0)
      formattedBill = 0;

    return formattedBill;
  }

  getFoodBillWithAlcohol() {
    let formattedBill = parseCurrencySafe(this.state.billTotal);
    let formattedService = this.state.serviceIncluded ? parseCurrencySafe(this.state.serviceTotal) : 0;
    let formattedAlcohol = this.state.alcoholIncluded ? 0 : parseCurrencySafe(this.state.alcoholTotal);

    formattedBill = formattedBill - formattedService + formattedAlcohol;

    if (formattedBill < 0)
      formattedBill = 0;

    return formattedBill;
  }

  getTotalBill() {
    let formattedBill = parseCurrencySafe(this.state.billTotal);
    let formattedService = this.state.serviceIncluded ? 0 : parseCurrencySafe(this.state.serviceTotal);
    let formattedAlcohol = this.state.alcoholIncluded ? 0 : parseCurrencySafe(this.state.alcoholTotal);

    formattedBill = formattedBill + formattedService + formattedAlcohol;

    return formattedBill;
  }

  calculateDiscount() {
    let discount = 0;
    if (this.state.discountIncluded) {
      if (this.state.discountFieldSelection === "amount") {
        discount = parseCurrencySafe(this.state.discountAmount);
      }
      else if (this.state.discountFieldSelection === "percentage") {
        let discountPercentage = parseCurrencySafe(this.state.discountPercentage);
        if (this.state.alcoholDiscountIncluded) {
          discount = discountPercentage / 100 * this.getFoodBillWithAlcohol();
        } else {
          discount = discountPercentage / 100 * this.getFoodBill();
        }
      }
    }
    return discount;
  }

  performCalculations() {
    let formattedNumberOfPeople = parsePeopleNumerSafe(this.state.peopleCount);
    let foodBill = this.getFoodBill();
    let getFoodBillWithAlcohol = this.getFoodBillWithAlcohol();

    let reclaimOnVoucher = 0;
    let clientToPay = 0;

    if (getFoodBillWithAlcohol > 0) {
      let discount = this.calculateDiscount();

      if (formattedNumberOfPeople > 0) {

        let foodDiscount = discount * foodBill / getFoodBillWithAlcohol;
        let billEligibleForVoucher = foodBill - foodDiscount;

        let uncapped = billEligibleForVoucher / 2;
        let maxDeduction = 10 * formattedNumberOfPeople;
        if ((uncapped) > (maxDeduction)) {
          reclaimOnVoucher = maxDeduction;
        } else {
          reclaimOnVoucher = uncapped;
        }
      }

      if (reclaimOnVoucher < 0) {
        reclaimOnVoucher = 0;
      }

      clientToPay = this.getTotalBill() - discount - reclaimOnVoucher;
    }

    this.setState({reclaimOnVoucher: reclaimOnVoucher.toFixed(2), clientToPay: clientToPay.toFixed(2)});
  }

  render() {
    const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayToday = new Date().getDay();
    const daysNotValid = [0,4,5,6];

    return (
      <div className="container">
        <h4 className="text-center">Eat Out To Help Out Voucher Calculator</h4>

        {(daysNotValid.indexOf(dayToday) > -1) &&
          <h5 className="text-danger text-center">Today is {weekDays[dayToday]}. The voucher scheme only applies on Mondays, Tuesday and Wednesdays</h5>
        }

        <form onSubmit={this.handleSubmit}>
          <BillTotal billTotal={this.state.billTotal} onBillTotalChange={this.handleBillTotalChange}
            serviceTotalRef={this.serviceTotalRef} />

          <ServiceCheckbox serviceIncluded={this.state.serviceIncluded}
            onServiceIncludedChange={this.handleServiceIncludedChange} />

          <ServiceTotal serviceTotal={this.state.serviceTotal} onServiceTotalChange={this.handleServiceTotalChange}
            ref={this.serviceTotalRef} alcoholTotalRef={this.alcoholTotalRef} />

          <AlcoholCheckbox alcoholIncluded={this.state.alcoholIncluded}
            onAlcoholIncludedChange={this.handleAlcoholIncludedChange} />

          <AlcoholTotal alcoholTotal={this.state.alcoholTotal} onAlcoholTotalChange={this.handleAlcoholTotalChange}
            ref={this.alcoholTotalRef} peopleCountRef={this.peopleCountRef} />

          <PeopleCount peopleCount={this.state.peopleCount} onPeopleCountChange={this.handlePeopleCountChange}
            ref={this.peopleCountRef} discountTotalRef={this.discountTotalRef}/>

          <DiscountCheckbox discountIncluded={this.state.discountIncluded}
            onDiscountIncludedChange={this.handleDiscountIncludedChange} />

          {this.state.discountIncluded === true &&
            <>
              <div className="form-group row">
                <DiscountAmount ref={this.discountTotalRef} discountAmount={this.state.discountAmount} onDiscountAmountChange={this.handleDiscountAmountChange} discountFieldSelection={this.state.discountFieldSelection}/>
                <DiscountPercentage discountPercentage={this.state.discountPercentage} onDiscountPercentageChange={this.handleDiscountPercentageChange} discountFieldSelection={this.state.discountFieldSelection} />
              </div>
              <DiscountIncludesAlcoholCheckbox alcoholDiscountIncluded={this.state.alcoholDiscountIncluded} onAlcoholDiscountIncludeChange={this.handleAlcoholDiscountIncludeChange} />
            </>
          }

          <ClientToPay clientToPay={this.state.clientToPay} />

          <ReclaimOnVoucher reclaimOnVoucher={this.state.reclaimOnVoucher} />

          <ResetButton onResetCalculator={this.resetCalculator} />
        </form>
      </div>
    );
  }
}

export default Calculator;
