import React from 'react';
import { currencyRe } from './currencyRe.js';
import { parseFloatToFixed } from './parseFloatToFixed';

var cN = require('classnames');

class DiscountAmount extends React.Component {
  constructor(props) {
    super(props);
    this.discountPercentageRef = React.createRef();
  }

  handleDiscountAmountChange = (event) => {
    if (event.target.validity.valid) {
      this.props.onDiscountAmountChange(event.target.value, true);
    }
  }

  formatDiscountAmountIntoCurrency = (event) => this.props.onDiscountAmountChange(parseFloatToFixed(event.target.value, false));

  render() {
    const dSF = this.props.discountFieldSelection;

    return (
      <>
        <label htmlFor="discountAmount" className="col-sm-5 col-form-label text-sm-right">Discounts</label>
        <div className="col width-12-rem">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className={cN({"input-group-text": true, "border-success": (dSF === "amount"), "border-danger": (dSF === "percentage")})}>£</div>
            </div>
            <input type="text" id="discountAmount" aria-describedby="discountAmount" placeholder="0.00" inputMode="decimal"
              className={cN({"form-control": true, "border-success": (dSF === "amount"), "border-danger": (dSF === "percentage")})}
              value={this.props.discountAmount} onChange={this.handleDiscountAmountChange}
              onBlur={this.formatDiscountAmountIntoCurrency} pattern={currencyRe} ref={this.props.innerRef} />
          </div>
        </div>
      </>
    )
  }
}

export default React.forwardRef((props, ref) => <DiscountAmount innerRef={ref} {...props} />);
