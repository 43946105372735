import React from 'react';
import { percentageRe } from './percentageRe.js';
import { parseFloatToFixed } from './parseFloatToFixed';

var cN = require('classnames');

class DiscountPercentage extends React.Component {
  handleDiscountPercentageChange = (event) => {
    if (event.target.validity.valid) {
      this.props.onDiscountPercentageChange(event.target.value, true);
    }
  }

  formatDiscountAmountIntoCurrency = (event) => this.props.onDiscountPercentageChange(parseFloatToFixed(event.target.value, false));

  render() {
    const dSF = this.props.discountFieldSelection;

    return (
      <>
        <label htmlFor="discountPercentage" className="col-sm col-form-label flex-basis-width-auto px-1">or</label>
        <div className="col width-8-rem">
          <div className="input-group">
            <input type="text" id="discountPercentage" aria-describedby="discountPercentage" placeholder="0" inputMode="decimal"
              className={cN({"form-control": true, "border-success": (dSF === "percentage"), "border-danger": (dSF === "amount")})}
              value={this.props.discountPercentage} onChange={this.handleDiscountPercentageChange} pattern={percentageRe}
              ref={this.discountPercentageRef}/>
            <div className="input-group-append">
              <div className={cN({"input-group-text": true, "border-success": (dSF === "percentage"), "border-danger": (dSF === "amount")})}>%</div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default DiscountPercentage;
