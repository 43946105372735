import React from 'react';

class DiscountIncludesAlcoholCheckbox extends React.Component {
  handleAlcoholDiscountIncludedChange = (event) => this.props.onAlcoholDiscountIncludeChange(event.target.checked);

  render() {
    return (
      <div className="form-group row">
        <div className="col-sm-5"></div>
        <div className="col">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="alcoholDiscountIncluded"
            checked={this.props.alcoholDiscountIncluded} onChange={this.handleAlcoholDiscountIncludedChange} />
            <label className="form-check-label" htmlFor="alcoholDiscountIncluded">
              Discount includes Alcohol?
            </label>
          </div>
        </div>
      </div>
    )
  }
}

export default DiscountIncludesAlcoholCheckbox;
