import React from 'react';

class ServiceCheckbox extends React.Component {
  handleServiceIncludedChange = (event) => this.props.onServiceIncludedChange(event.target.checked);

  render() {
    return (
      <div className="form-group row">
        <div className="col-sm-5"></div>
        <div className="col">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="serviceIncluded"
            checked={this.props.serviceIncluded} onChange={this.handleServiceIncludedChange} />
            <label className="form-check-label" htmlFor="serviceIncluded">
              Total includes service?
            </label>
          </div>
        </div>
      </div>
    )
  }
}

export default ServiceCheckbox;
