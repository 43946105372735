import React from 'react';

class AlcoholCheckbox extends React.Component {
  handleAlcoholIncludedChange = (event) => {
    this.props.onAlcoholIncludedChange(event.target.checked);
  }

  render() {
    return (

      <div className="form-group row">
        <div className="col-sm-5"></div>
        <div className="col">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="alcoholIncluded"
            checked={this.props.alcoholIncluded} onChange={this.handleAlcoholIncludedChange} />
            <label className="form-check-label" htmlFor="alcoholIncluded">
              Total includes Alcohol?
            </label>
          </div>
        </div>
      </div>
    )
  }
}

export default AlcoholCheckbox;
