import React from 'react';
import { peopleCountRe } from './peopleCountRe.js';

class PeopleCount extends React.Component {
  handlePeopleCountChange = (event) => {
    if (event.target.validity.valid) {
      this.props.onPeopleCountChange(event.target.value);
    }
  }

  handleEnterKeyForPeopleCount = (event) => {
    if (event.keyCode === 13 && this.props.discountTotalRef.current !== null) {
      this.props.discountTotalRef.current.focus();
    }
  }

  render() {
    return (
      <div className="form-group row">
        <label htmlFor="numberOfPeople" className="col-sm-5 col-form-label text-sm-right">Number of People</label>
        <div className="col width-12-rem">
          <input type="text" className="form-control" id="numberOfPeople" aria-describedby="numberOfPeople"
            value={this.props.peopleCount} onChange={this.handlePeopleCountChange}
            ref={this.props.innerRef} placeholder="1" onKeyDown={this.handleEnterKeyForPeopleCount}
            inputMode="numeric" pattern={peopleCountRe} />
        </div>
      </div>
    )
  }
}

export default React.forwardRef((props, ref) => <PeopleCount innerRef={ref} {...props} />);
