import React from 'react';
import { currencyRe } from './currencyRe.js';

class AlcoholTotal extends React.Component {
  handleAlcoholTotalChange = (event) => {
    if (event.target.validity.valid) {
      this.props.onAlcoholTotalChange(event.target.value);
    }
  }

  formatAlcoholTotalIntoCurrency = (event) => {
    let alcoholTotal = event.target.value;

    if (alcoholTotal !== "") {
      let alcoholTotalParsed = Number.parseFloat(alcoholTotal);
      let alcoholTotalFormatted = alcoholTotalParsed.toFixed(2);
      this.props.onAlcoholTotalChange(alcoholTotalFormatted);
    }
  }

  handleEnterKeyForAlcoholTotal = (event) => {
    if (event.keyCode === 13) {
      this.props.peopleCountRef.current.focus();
    }
  }

  render() {
    return (
      <div className="form-group row">
        <label htmlFor="alcoholTotal" className="col-sm-5 col-form-label text-sm-right">Alcohol Total</label>
        <div className="col width-12-rem">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">£</div>
            </div>
            <input type="text" className="form-control" id="alcoholTotal" aria-describedby="alcoholTotal"
              value={this.props.alcoholTotal} onChange={this.handleAlcoholTotalChange}
              onBlur={this.formatAlcoholTotalIntoCurrency} onKeyDown={this.handleEnterKeyForAlcoholTotal}
              ref={this.props.innerRef} placeholder="0.00" inputMode="decimal" pattern={currencyRe}/>
          </div>
        </div>
      </div>
    )
  }
}

export default React.forwardRef((props, ref) => <AlcoholTotal innerRef={ref} {...props} />);
