import React from 'react';

class ResetButton extends React.Component {
  resetCalculator = (event) => {
    this.props.onResetCalculator();
  }

  render() {
    return (
      <div className="form-group row">
        <label htmlFor="numberOfPeople" className="col-sm-5 col-form-label text-sm-right"></label>
        <div className="col">
          <button type="button" className="btn btn-primary" onClick={this.resetCalculator}>
            Reset Calculator
          </button>
        </div>
      </div>
    )
  }
}

export default ResetButton;
